import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dot } from 'lucide-react';

function Navbar() {

  function setUnderline() {
    const navbarLinks = document.querySelectorAll('.navbar-links div');
    const currentPath = window.location.pathname + window.location.hash;
    navbarLinks.forEach(link => {
      const linkPath = link.firstChild.getAttribute('href');
      const linkPath_fix = "/" + linkPath
      if (linkPath_fix === currentPath) {
        link.style.textDecoration = 'underline';
        console.log(linkPath);
      } else {
        link.style.textDecoration = 'none';
      }
    });
  }

  function removeUnderline() {
    const navbarLinks = document.querySelectorAll('.navbar-links div');
    navbarLinks.forEach(link => {
      link.style.textDecoration = 'none';
    });
  }

  const addBrackets = (event) => {
      const div = event.target;
      const textNode = div.firstChild;
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        textNode.textContent = `[${textNode.textContent}]`;
        console.log(textNode.textContent);
        div.style.color = 'grey';
      }
    };
    
  const removeBrackets = (event) => {
      const div = event.target;
      const textNode = div.firstChild;
      if (textNode && textNode.nodeType === Node.TEXT_NODE) {
        textNode.textContent = textNode.textContent.replace(/^\[|\]$/g, '');
        console.log(textNode.textContent);
        div.style.color = 'black';
      }
    };

  
  useEffect(() => {
    setUnderline();

    const navbar = document.querySelector('.navbar');
    const links = document.querySelectorAll('.navbar-links div');

   if (navbar) {
      links.forEach(link => {
        link.addEventListener('mouseover', addBrackets);
        link.addEventListener('mouseout', removeBrackets);
      });
    }
   
    
    return () => {
        links.forEach(link => {
          link.removeEventListener('mouseover', addBrackets);
          link.removeEventListener('mouseout', removeBrackets);
        });
        removeUnderline();

      }
  }, [window.location.pathname]);

  return (
  <section className="navbar-container">
      <nav className="navbar"> 
          <div className="navbar-links">
              <div><Link to="/work">Work</Link></div>
              <Dot />
              <div><Link to="/projects">Projects</Link></div>
              <Dot />
              <div><Link to="/">Home</Link></div>
              <Dot />
              <div><Link to="/about">About Me</Link></div>
              <Dot />
              <div><a href="https://blog.yougao.dev/" target="_blank">To Blog</a></div>
          </div>
      </nav>
 </section>
  );
};

export default Navbar;
