import Navbar from '../navbar/Navbar';
import './Home.css'; 
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';   


function Home() {
    const canvasRef = useRef(null);
    const fps = 30; // Set a valid frame rate
    const interval = 1000 / fps;
    let lastTime = 0;
    const headingRef = useRef(null);
    const mottoRef = useRef(null);
    const likesRef = useRef(null);
    const mottoLiRefs = useRef([]);
    const likesLiRefs = useRef([]);
    const bottomRef = useRef(null);
    const sphereRef = useRef(null);
    const image1Ref = useRef(null);
    const image2Ref = useRef(null);
    const image3Ref = useRef(null);

    useEffect(() => {
        let scene, camera, renderer, model;
        let isAnimatingHome = true;

        gsap.fromTo(canvasRef.current, { opacity: 0}, { opacity: 1, duration: 2, delay: .2, ease: 'power2.inOut' });
        gsap.fromTo(headingRef.current, { opacity: 0, y: -100}, { y:0, opacity: 1, duration: 2, delay: .5, ease: 'power2.inOut' });
        gsap.fromTo(mottoRef.current, { opacity: 0, x: -100}, { x:0, opacity: 1, duration: 1.5, delay: 1, ease: 'power2.inOut' });
        gsap.fromTo(likesRef.current, { opacity: 0, x: -100}, { x:0, opacity: 1, duration: 1.5, delay: 3, ease: 'power2.inOut' });
        gsap.fromTo(bottomRef.current, { opacity: 0, y: 100}, { y:0, opacity: 1, duration: 2, delay: 5, ease: 'power2.inOut' });
        gsap.fromTo(image1Ref.current, { opacity: 0, y: 100}, { y:0, opacity: 1, duration: 2, delay: 5.5, ease: 'power2.inOut' });
        gsap.fromTo(image2Ref.current, { opacity: 0, y: 75}, { y:0, opacity: 1, duration: 2, delay: 5.75, ease: 'power2.inOut' });
        gsap.fromTo(image3Ref.current, { opacity: 0, y: 50}, { y:0, opacity: 1, duration: 2, delay: 6, ease: 'power2.inOut' });
        gsap.fromTo(sphereRef.current, { opacity: 0}, { opacity: 1, duration: 2, delay: 6.75, ease: 'power2.inOut' });

        mottoLiRefs.current.forEach((li, index) => {
            gsap.fromTo(li, 
                { opacity: 0, x: -100}, 
                { opacity: 1, x:0, duration: 2, delay: 2 + index * .5 }
            );
        });

        likesLiRefs.current.forEach((li, index) => {
            gsap.fromTo(li, 
                { opacity: 0, x: -100}, 
                { opacity: 1, x:0, duration: 1, delay: 4 + index * .5 }
            );
        });

        gsap.to([sphereRef.current], {
            color: '#ffffff', // Target color
            duration: 1,
            repeat: 7,
            yoyo: true,
            ease: 'power2.inOut',
            onComplete: () => {
                gsap.to([sphereRef.current], {
                    color: '#ffffff', // Set back to black
                    duration: 1,
                    ease: 'power2.inOut'
                });
            }
        });

        function init() {
            // Create scene
            scene = new THREE.Scene();
            scene.background = new THREE.Color(0xffffff);

            camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
            camera.position.set(.5, .2, 2.5); 
            
            renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
            renderer.setSize(window.innerWidth / 2, window.innerHeight / 1.65);

            // Darken Scene
            const ambientLight = new THREE.AmbientLight(0x404040);
            scene.add(ambientLight);


            // Add orbit controls
            const controls = new OrbitControls(camera, renderer.domElement);
            controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
            controls.dampingFactor = 0.25;

	    // Middle sphere
            const middle_sphere = new THREE.SphereGeometry(.8, 32, 32);
            const middle_material = new THREE.PointsMaterial({ color: 0xffffff, size: .2 });
            const middle_points = new THREE.Points(middle_sphere, middle_material);
            scene.add(middle_points);

	    // Particles around middle
            // Particles around middle (convert to lines)
		const particles_x = new THREE.Group();
		const particles_y = new THREE.Group();
		const particles_z = new THREE.Group();

		const particlesGeometry = new THREE.BufferGeometry();
		const positions = [];

		const particlesMaterial = new THREE.LineBasicMaterial({ color: 0x000000, linewidth: 8});

		// Create particles_x (around X-axis)
		for (let i = 0; i < 21; i++) {
		    const angle = i / 20 * Math.PI * 2;
		    const x = Math.sin(angle) * 1.5;
		    const y = Math.cos(angle) * 1.5;
		    const z = 0;
		    positions.push(x, y, z);
		}

		particlesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

		// Create the line object for particles_x
		const particleLine_x = new THREE.Line(particlesGeometry, particlesMaterial);
		particles_x.add(particleLine_x);
		scene.add(particles_x);

		// Create particles_y (around Y-axis)
		const positions_y = [];
		for (let i = 0; i < 22; i++) {
		    const angle = i / 20 * Math.PI * 1.9;
		    const x = Math.sin(angle) * 1.9;
		    const y = 0;
		    const z = Math.cos(angle) * 1.9;

		    positions_y.push(x, y, z);
		}

		// Create the geometry for the line along Y-axis
		const particlesGeometry_y = new THREE.BufferGeometry().setAttribute(
		    'position',
		    new THREE.Float32BufferAttribute(positions_y, 3)
		);

		const particleLine_y = new THREE.Line(particlesGeometry_y, particlesMaterial);
		particles_y.add(particleLine_y);
		scene.add(particles_y);

		// Create particles_z (around Z-axis)
		const positions_z = [];
		for (let i = 0; i < 21; i++) {
		    const angle = i / 20 * Math.PI * 2;
		    const x = 0;
		    const y = Math.sin(angle) * 1.5;
		    const z = Math.cos(angle) * 1.5;

		    positions_z.push(x, y, z);
		}

		// Create the geometry for the line along Z-axis
		const particlesGeometry_z = new THREE.BufferGeometry().setAttribute(
		    'position',
		    new THREE.Float32BufferAttribute(positions_z, 3)
		);

		const particleLine_z = new THREE.Line(particlesGeometry_z, particlesMaterial);
		particles_z.add(particleLine_z);
		scene.add(particles_z);

		const particles_x_clone = new THREE.Group()
		// Create particles_x (around X-axis)
		for (let i = 0; i < 21; i++) {
		    const angle = i / 20 * Math.PI * 2;
		    const x = Math.sin(angle) * 1.5;
		    const y = Math.cos(angle) * 1.5;
		    const z = 0;
		    positions.push(x, y, z);
		}

		particlesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

		// Create the line object for particles_x
		const particleLine_x_clone = new THREE.Line(particlesGeometry, particlesMaterial);
		particles_x_clone.add(particleLine_x_clone);
		scene.add(particles_x_clone);

        // Function to handle window resize
        function onWindowResize() {
            // Update camera aspect ratio and projection matrix
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();

            // Update renderer size 
            renderer.setSize(window.innerWidth / 2 , window.innerHeight / 1.5);
        }

        // Add event listener for window resize
        window.addEventListener('resize', onWindowResize, false);

        // Animation loop
        // Render loop
        const animate = (time) => {
            if (isAnimatingHome) {
                requestAnimationFrame(animate);
                
                // Calculate the time difference
                const delta = time - lastTime;
                // If enough time has passed, render the frame
                if (delta > interval) {
                    // Spin the Middle Sphere
                    middle_points.rotation.x += 0.05;

                    // Update Rotations

		    particles_x.rotation.x += 0.03;
                    particles_x.rotation.y += 0.03;
                    particles_x.rotation.z += 0.05;
		    
		    particles_x_clone.rotation.x -= 0.03;
                    particles_x_clone.rotation.y += 0.03;
                    particles_x_clone.rotation.z -= 0.05;

                    particles_y.rotation.y += 0.05;

                    particles_z.rotation.y += 0.03;
                    particles_z.rotation.z += 0.05;

                    lastTime = time - (delta % interval);        
                    controls.update();
                    renderer.render(scene, camera);
                }
            }
        };
            animate();
        }

        init();

        // Cleanup on component unmount
        return () => {
            isAnimatingHome = false;
            if (renderer) {
                console.log('Disposing renderer');
                renderer.dispose();
            }
            if (scene) {
                console.log('Disposing scene');
                scene.traverse((object) => {
                    if (object.geometry) {
                        object.geometry.dispose();
                    }
                    if (object.material) {
                        if (Array.isArray(object.material)) {
                            object.material.forEach((material) => {
                                material.dispose();
                                if (material.map) {
                                    material.map.dispose();
                                }
                            });
                        } else {
                            object.material.dispose();
                            if (object.material.map) {
                                object.material.map.dispose();
                            }
                        }
                    }
                    if (object instanceof THREE.WebGLRenderTarget) {
                        object.dispose();
                    }
                });
            }
        };
    }, []);

return (
<section className="home">
    <Navbar />
    <div>   
    <table className="table-container">
        <tr> 
            <td className="table-cell" style={{ textAlign: 'right' }}>
                 <div ><h1 ref={headingRef} style={{textAlign: 'right'}}>hi i'm you :)</h1></div>
                 <div ref={mottoRef}>
                 <h2 className="dynamic-margin-head" style={{color: "grey", marginBot: "0px"}}>my work philosophy:</h2>
                 <ul> 
                    <li ref={el => mottoLiRefs.current[0] = el}>
                        <h1>minimal, sustainable,</h1>
                    </li>
                    <li ref={el => mottoLiRefs.current[1] = el}>
                        <h1>pragmatic & effective</h1>
                    </li>
                </ul>
                 </div>


                <div ref={likesRef}>
                <h2 style={{color: "grey"}} className="dynamic-margin-head">i'm dedicated to:</h2>
                <ul>
                    <li ref={el => likesLiRefs.current[0] = el}>
                        <h1>making cool things</h1>
                    </li>
                    <li ref={el => likesLiRefs.current[1] = el}>
                        <h1 >learning new stuff</h1>
                    </li>
                    <li ref={el => likesLiRefs.current[2] = el}>
                        <h1>helping people</h1>
                    </li>
                </ul>
                </div>

                <div ref={bottomRef}>
                <h2 className="dynamic-margin" style={{textAlign: 'right', color: "grey", marginBot: "0px"}}>stay connected!</h2>
                <div style={{display: 'flex', justifyContent: 'right'}}>
                    <a href="https://www.linkedin.com/in/gao-you/" target="_blank" rel="noreferrer">
                    <img ref={image1Ref} style={{height: '50px', width: '50px', margin: '0 10px'}} src="linked_logo.png" alt="Image 1"/>
                    </a>
                    <a href="https://www.github.com/you-gao/" target="_blank" rel="noreferrer">
                    <img ref={image2Ref} style={{height: '50px', width: '50px', margin: '0 10px'}} src="github_logo.png" alt="Image "/>
                    </a>
                    <a href="https://stackoverflow.com/users/26650867/you-gao" target="_blank" rel="noreferrer">
                    <img ref={image3Ref} style={{height: '50px', width: '50px'}} src="stack_logo.png" alt="Image 3"/>
                    </a>
                </div>
                </div>
            </td>
            <td className="table-cell hide-on-small">
                <canvas ref={canvasRef} />
                <h1 style={{textAlign: 'center'}} ref={sphereRef}>gaze into the sphere.</h1>
            </td>
        </tr>
    </table>
    </div>
</section>
);
};

export default Home;
