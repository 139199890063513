import Navbar from '../navbar/Navbar';
import './About.css';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef, useEffect } from "react";
import React from "react";
gsap.registerPlugin(ScrollTrigger);

function About() {
  const initializeAnimations = () => {
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    
    const panels = document.querySelectorAll('.apanel');
    const end_percent = window.innerWidth <= 700 ? "20%" : "90%";
    
    console.log(end_percent, window.innerWidth);
  
    // Reusable animation function
    const animateElement = (element, fromVars, toVars) => {
      if (element) {
        gsap.fromTo(element, fromVars, { ...toVars, ease: "power2" });
      }
    };
  
    panels.forEach((panel, i) => {
      // Pinning panels
      ScrollTrigger.create({
        trigger: panel,
        start: "top top",
        end: end_percent,
        pin: true,
        pinSpacing: true,
      });
  
      // Name animation
      const nameElement = panel.querySelector("#name");
      if (nameElement) {
        animateElement(
          nameElement,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
              trigger: panel,
              start: i === 0 ? "top 70%" : "top center",
              end: "bottom",
              toggleActions: i === 0 ? "play none play reverse" : undefined,
              scrub: i === 0 ? false : true,
            },
          }
        );
      }
  
      // Heading 2 animation
      panel.querySelectorAll(".under-center h2").forEach(h2 => {
        animateElement(
          h2,
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1,
            delay: 0.5,
            scrollTrigger: {
              trigger: i === 0 ? h2 : panel,
              start: i === 0 ? "top center" : "top center",
              end: "bottom top",
              toggleActions: i === 0 ? "play none none reverse" : undefined,
              scrub: i === 0 ? false : true,
            },
          }
        );
      });
  
      // List item animations
      const liElements = panel.querySelectorAll(".under-center li");
      const smallScreen = window.innerHeight < 800;
      
      liElements.forEach((li, index) => {
        animateElement(
          li,
          { opacity: 0, x: 100 + index * 100 },
          {
            opacity: 1,
            x: 0,
            duration: 1.25,
            delay: 0.1,
            scrollTrigger: {
              trigger: panel,
              start: i === 0 ? "top 70%" : "top center",
              end: smallScreen ? undefined : "center",
              scrub: false,
              toggleActions:  "play none play reverse",
            },
          }
        );
      });
  
      // Image animations
      const imageAnimations = [
        { selector: ".middle-top-img", fromVars: { opacity: 0, y: -100 } },
        { selector: ".middle-left-rotated-img", fromVars: { opacity: 0, x: -100 } },
        { selector: ".middle-right-rotated-img", fromVars: { opacity: 0, x: 100 } },
        { selector: ".middle-bottom-img", fromVars: { opacity: 0, y: 100 } },
      ];
  
      imageAnimations.forEach(({ selector, fromVars }) => {
        const image = panel.querySelector(selector);
        if (image) {
          animateElement(
            image,
            fromVars,
            {
              opacity: 1,
              x: fromVars.x !== undefined ? 0 : undefined,
              y: fromVars.y !== undefined ? 0 : undefined,
              duration: 1,
              scrollTrigger: {
                trigger: panel,
                start: "top center",
                end: "bottom",
                toggleActions: "play revserse play reverse",
              },
            }
          );
        }
      });
  
    });
  
    ScrollTrigger.refresh();
  };
      

  useEffect(() => {
     initializeAnimations();
     ScrollTrigger.refresh();
    const handleResize = () => {
      initializeAnimations();
    };
    const mediaQuery = window.matchMedia('(min-width: 768px)'); // Adjust the width as per your mobile breakpoint

    if (mediaQuery.matches) {
      window.addEventListener('resize', handleResize);

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    window.addEventListener('load', function() {
      ScrollTrigger.refresh();
    });
    }


  return () => {
    if (mediaQuery.matches) {
      window.removeEventListener('resize', handleResize);
    }
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    window.scrollTo(0, 0);
  };
}, []);

  return (
    <section className="about">
    <Navbar />
    <div>
      <section id="x" className="apanel">
        <img className="middle-top-img" src="uva_banner.png" />
        <img className="middle-left-rotated-img" src="uva_cav.png" />
        <img className="middle-right-rotated-img" src="uva_cs.jpg" />
        <img className="middle-bottom-img" src="desk.png" />
        <div className="overlay-text-down">Scroll v</div>
        <table className="center">
          <td>
          <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
          </td>
          <td style={{border: "3px solid black"}}>
          <div id="name" style={{marginLeft:"10px",marginRight:"10px"}}>Student</div>
          </td>
        </table>
        <div className="under-center">
          <h2 style={{textAlign:"center"}}>Relevant Information:</h2>
          <ul>
            <li>CS & GDS Majors</li>
            <li>DS Minor</li>
            <li>1 More Year...</li>
          </ul>
        </div>
      </section>

      <section id="x" className="apanel">
        <a href="https://www.yelp.com/collections/user?userid=xft100yz_n8JnvN8MUxSlw" target="_blank">
            <img className="middle-top-img" style={{height: "20%", width: "25%", top: "5%", left:"39%"}} src="yelp_t.png" />
        </a>
        <img className="middle-left-rotated-img" style={{transform: "rotate(0deg)", width: "20%", height: "35%", left:"12.5%"}} src="rice.png" />
        <img className="middle-right-rotated-img" style={{transform: "rotate(30deg)", width: "25%", height: "35%", left:"65%"}} src="mango4.png" />
        <img className="middle-bottom-img" src="eating.png" />
        <table className="center">
          <td>
          <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
          </td>
          <td style={{border: "3px solid black"}}>
          <div id="name" style={{marginLeft:"10px",marginRight:"10px"}}>Foodie</div>
          </td>
        </table>
        <div className="under-center">
          <h2 style={{textAlign:"center"}}>Recent Places Went:</h2>
          <ul> 
            <li>Hotpot 757</li>
            <li>Thai Cuisine & Noodle House</li>
            <li>Umma&#39;s</li>
          </ul>
        </div>
      </section>

      <section id="x" className="apanel">
      <img className="middle-top-img" style={{height: "20%", width: "50%", top: "5%", left:"25%"}} src="pokeballs.png" />
      <img className="middle-left-rotated-img" style={{transform: "rotate(0deg)", left:"17%"}} src="kirby.png" />
      <img className="middle-right-rotated-img" style={{transform: "scaleX(-1)", left:"67.5%"}} src="sonic.png" />
      <img className="middle-bottom-img" src="gaming.png" />

        <table className="center">
          <td>
          <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
          </td>
          <td style={{border: "3px solid black"}}>
          <div id="name"  style={{marginLeft:"10px",marginRight:"10px"}}>Gamer</div>
          </td>
        </table>
        <div className="under-center">
          <h2 style={{textAlign:"center"}}>Favorite Games ATM:</h2>
          <ul>
            <li>Digimon Cyber Sleuth</li>
            <li>Civilization 5</li>
            <li>Pokemon Showdown</li>
          </ul>
        </div>
      </section>

      <section className="apanel">
      <a href="https://www.goodreads.com/user/show/183098989-you-gao" target="_blank">
      <div className="middle-top-img" 
      style={{ marginTop: "revert", justifyContent: "left", alignItems: "left", position: "absolute", width: "100%", left: "0", textAlign: "center", fontSize: "30px", fontWeight: "600", whiteSpace: "nowrap"}}>
      <p className='mb-0 mt-0'>"[                   ] -- insert reflective and deep quote"</p>
      </div>
      </a>
      <img className="middle-left-rotated-img" style={{height: "40%", transform: "rotate(0deg)", left:"17%"}} src="bookie.jpg" />
      <img className="middle-right-rotated-img" style={{height: "40%", left:"68%"}} src="luffy.png" />
      <img className="middle-bottom-img" src="reading.png" />

        <table className="center">
          <td>
          <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
          </td>
          <td style={{border: "3px solid black"}}>
          <div id="name" style={{marginLeft:"10px",marginRight:"10px"}}>Reader</div>
          </td>
        </table>
        <div className="under-center">
          <h2 style={{textAlign:"center"}}>Current Reads:</h2>
          <ul>
            <li>Sakamoto Days</li>
            <li>The Player of Games</li>
            <li>Organizational Culture</li>
          </ul>
        </div>
      </section>

      <section className="apanel">
      <a href="https://blog.yougao.dev/" target="_blank" >
      <img className="middle-top-img" style={{height: "10%", width: "40%", top: "12.5%", left:"32.5%"}} src="pen.png" />
      </a>
      <img className="middle-left-rotated-img" style={{transform: "rotate(0deg)", left:"17%"}} src="notepad.png" />
      <img className="middle-right-rotated-img" style={{transform: "rotate(15deg) scaleX(-1)", left:"70%", top:"25%"}} src="thought.png" />

      <img className="middle-bottom-img" src="blogging.png" />
        <table className="center">
          <td>
          <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
          </td>
          <td style={{border: "3px solid black"}}>
          <div id="name" style={{marginLeft:"10px",marginRight:"10px"}}>Blogger</div>
          </td>
        </table>
        <div className="under-center">
          <h2 style={{textAlign:"center"}}>Latest Ponderings:</h2>
          <ul>
            <li>Complexity Science</li>
            <li>async/parallel</li>
            <li>Bias</li>
          </ul>
        </div>
      </section>

      <section id="x" className="apanel">
          <img className="middle-top-img" style={{height: "10%", top: "10%"}} src="forkbomb.png" />
          <img className="middle-left-rotated-img" style={{transform: "rotate(0deg)", left:"17.5%"}} src="arch.png" />
          <img className="middle-right-rotated-img" style={{transform: "scaleX(-1)", left:"67.5%"}} src="gnu.png" />
          <img className="middle-bottom-img" src="developer.png" />

            <table className="center">
              <td>
              <div style={{marginRight:"10px", color:"grey"}}>i am a: </div>
              </td>
              <td style={{border: "3px solid black"}}>
              <div id="name" style={{marginLeft:"10px",marginRight:"10px"}}>Developer</div>
              </td>
            </table>
            <div className="under-center">
              <h2 style={{textAlign:"center"}}>Current Interests:</h2>
              <ul>
                <li>Distributed Systems</li>
                <li>Developer Tools</li>
                <li>Linux Kernel</li>
              </ul>
            </div>
          </section>

    </div>
    </section>
  );
}


export default About;
