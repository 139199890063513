import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import './Projects.css';
import { Star, GitFork } from 'lucide-react';

function Project() {
    const [personalProjects, setPersonalProjects] = useState([]);
    const [forkedProjects, setForkedProjects] = useState([]);
    const [classRepos, setClassRepos] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    async function getProjects() {
        const response = await fetch('https://api.github.com/users/You-Gao/repos');
        const response_p = await fetch('https://api.github.com/users/alzox/repos');
        const data_p = await response_p.json();
        const data = await response.json();
        const mergedData = data.concat(data_p);

        // remove the repo named "You-Gao"
        const index = mergedData.findIndex(project => project.name === 'You-Gao');
        mergedData.splice(index, 1);

        return mergedData;
    }

    function getForkedProjects(projects) {
        console.log(projects);
        return projects.filter(project => project.fork);
    }

    async function getRealForkedProjects(forked_projects) {
        const projects = [];
        for (const project of forked_projects) {
            const response = await fetch(`https://api.github.com/repos/You-Gao/${project.name}`);;
            const data = await response.json();

            const response_p = await fetch(`https://api.github.com/repos/${data.parent.full_name}`);;
            const data_p = await response_p.json();

            // change data_p.stargazers_count and data_p.forks_count to a string /1000
            data_p.stargazers_count = (data_p.stargazers_count / 1000).toFixed(1) + 'k';
            data_p.forks_count = (data_p.forks_count / 1000).toFixed(1) + 'k'; 

            project.stargazers_count = data_p.stargazers_count;
            project.forks_count = data_p.forks_count;
            project.url = data_p.html_url;
            project.language = data_p.language;

            projects.push(project);
        } 
        return projects;
    }
    
    function getUnforkedProjects(projects) {
        return projects.filter(project => !project.fork);
    }

    function getClassRepos(projects) {
        return projects.filter(project => project.name.includes('cs-') | project.name.includes('ds-'));
    }
    
    function makeCard(data) {
        const description = data.description;
        console.log(description);
        //  ensure its a string
        data.description = description ? description : '';
        console.log(data.description);
        //  if the description is longer than 75 characters, cut it off and add '...'
        if (data.description.length > 75) {
            data.description = data.description.slice(0, 75) + '...';
        }
        return (
            <div className="col-md-6 col-lg-4">
                <div className="card">
                    <div className="card-body text-start">
                        <div className="card-title d-flex mb-0 align-items-baseline justify-content-between">
                            <h5>{data.name}</h5>
                            {/* only return stars and forks for forked projects  */}
                            {data.stargazers_count && data.forks_count && data.fork ? (
                                <div className="d-flex align-items-center">
                                    <Star size={16} />
                                    <span>{data.stargazers_count}</span>
                                    <span style={{ margin: '0 8px' }}></span>
                                    <div className="d-flex align-items-center">
                                        <GitFork size={16} />
                                        <span>{data.forks_count}</span>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="card-text mb-2">
                            <p>{data.description}</p>
                        </div>

                        <a href={data.html_url} target="_blank" rel="noreferrer" className="stretched-link"></a>

                        <div className="d-flex justify-content-start">
                            {data.language ? (
                                <div className="badge bg-primary me-2">{data.language}</div>
                            ) : null}
                        </div> 

                    </div>
                </div>
            </div>
        );
    }

        useEffect(() => {
            async function fetchData() {
                const data = await getProjects();
                const forkedProjects = getForkedProjects(data);
                const unforkedProjects = getUnforkedProjects(data);
                const classRepos = getClassRepos(data);
    
                const personalProjects = unforkedProjects.filter(project => !classRepos.includes(project));
                const realForkedProjects = await getRealForkedProjects(forkedProjects);
    
                console.log(realForkedProjects);
                setForkedProjects(realForkedProjects);
                setClassRepos(classRepos);
                setPersonalProjects(personalProjects);
            }
    
            fetchData().then(() => setIsLoaded(true));
        }, []);
        return (
            <section className="project">
                { isLoaded ? null : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                <Navbar />
                <div className="container mt-5">
                    <section id="personal" className="row">
                        <div className="col-md-12 text-start mb-2">
                            <h4>personal</h4>
                        </div>
                        {personalProjects.map(project => makeCard(project))}
                    </section>
    
                    <section id="open-source" className="row mt-3">
                        <div className="col-md-12 text-start mb-2">
                            <h4>open-source</h4>
                        </div>
                        {forkedProjects.map(project => makeCard(project))}
                    </section>
    
                    <section id="class" className="row mt-3">
                        <div className="col-md-12 text-start mb-2">
                            <h4>courses</h4>
                        </div>
                        {classRepos.map(project => makeCard(project))}
                    </section>
                </div>
            </section>
        );
}

export default Project;